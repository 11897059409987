import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faPauseCircle } from '@fortawesome/free-solid-svg-icons'

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';
import AppLayout from './CustomAppTabLayout';
import AudioPlayer from 'react-h5-audio-player';


import '../../assets/scss/custom-tab-styles/style.scss';
import { toast } from 'react-toastify';
import { CloudId } from '../../constant';

class VoiceMailSettings extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            recordType: 'vmail/new',
            voiceMails: [],
            username: "Daniyal",
            loading: false,
            audioSource: '',
            greetingDescription: '',
            showModal: false,
            userDeviceSelected: "",
            showGreetingInfo: false,
            lastIndexOfGreeting: 0,
            selectedGreeting: "",
            data: {},
            showEngage: null,
            notes:''

        };
        this.playRef = null;
        this.stopRef = null;
    }

    inputChangeHandler = (evt) => {

        const target = evt.target;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        if (value === true) {
            value = "yes";
        }

        if (value === false) {
            value = "no";
        }
        

        let data = this.state.data;
        data[name] = value;
        this.setState({ data });
    }
    
    getDeviceInfo = (userName, domain, uType, password,cloudId) => {
        
        const payLoad = new FormData();
        payLoad.append('device', "sip:" + userName.split("@")[0] + "@" + userName.split("@")[1]);
        payLoad.append('domain', userName.split("@")[1] + ".ALLEG");
        payLoad.append('cloud_id', cloudId);
        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=device&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            this.setState({username: (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1])});
            this.getSettings(uType, (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1]), domain, password, cloudId);
            this.getAnsweringRules(uType, (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1]),domain, password,cloudId);
            this.getGreetings(uType, (response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1]), domain, password,cloudId);
            this.getDeviceInfoForGreeting((response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1]), domain, uType, password,cloudId);
            // this.listGreetings((response.data[0].aor_user) +'@'+((response.data[0].sub_login).split("@")[1]), uType, password);

            const items = response.data.map((value, index) => {
                let displayValue = value.aor.split("@")[0];
                displayValue = displayValue.replace("sip:", "");
                return(<option value={displayValue}> {displayValue}</option>)
    
            });
    
            this.setState({userDevices: items});
            this.setState({loading: false});

        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false});
        });
    }

    checkIfShowEngage = (username, cloudId) => {
        const payLoad = {
            domain: username.split('@')[1],
            cloud_id: cloudId,
            extension: username.split('@')[0],
        } 

        axios.post(`${process.env.REACT_APP_API_URL}customWebTabCheck`, payLoad)
        .then(response=>{
            this.setState({showEngage: true});
        })
        .catch(err=>{
            console.log(err);
            this.setState({showEngage: false});
        });
    }

    componentDidMount(){

        // infoOnExistingUser will provide the settings data

        let url = decodeURIComponent(window.location.href)

        url = url.substring(url.lastIndexOf("?username="),url.length);
        this.setState({url: url});

        let username = url.substring(url.lastIndexOf("?username=") + 10,url.lastIndexOf("&password="));
        let password = url.substring(url.lastIndexOf("&password=") + 10,url.lastIndexOf("&cloud_id="));
        let cloudId = url.substring(url.lastIndexOf("&cloud_id=") + 10,url.lastIndexOf("&direct="));
        let direct = url.substring(url.lastIndexOf("&direct=") + 8,url.length);

        cloudId = cloudId.replace("*", "")
        password = encodeURIComponent(password);
        this.setState({username: username});
        this.setState({password: password});
        this.setState({cloudId: cloudId});
        this.setState({direct: direct});

        this.checkIfShowEngage(username, cloudId)

        let uType = 's';
        if(username.includes("QR")){
            // Here everything is based upon superuser
            this.getDeviceInfo(username, uType, password, cloudId);
            this.setState({uType: uType});
            return
        }

        // Here we will get everything based upon simple user
        uType = 'u';
        const payLoad = {
            user: username,
            password: password,
            cloud_id: cloudId,
        }
        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}/signin`, payLoad)
        .then(response=>{
            username =  username.split("@")[0] + "@" + response.data.domain;
            this.setState({username: username});
            this.setState({uid: response.data.uid});
            this.setState({signinDomain: response.data.domain});
            this.getSettings(uType, username, response.data.domain, password,cloudId);
            this.setState({uType: uType});
            this.getNotes(username,password);
            this.getAnsweringRules(uType, username, response.data.domain,  password,cloudId);
            this.getGreetings(uType, username, response.data.domain,  password,cloudId);
            this.getDeviceInfoForGreeting(username, response.data.domain,  uType, password,cloudId);
            // this.listGreetings(username, uType, password);

        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false});
        });

    }


    getSettings = (uType, userName, domain, password, cloudId) =>{

        const payLoad = new FormData();
        payLoad.append('user', userName.split("@")[0]);
        payLoad.append('domain', domain);
        payLoad.append('cloud_id', cloudId);
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            if(response.data[0]){
                this.setState({smsani: response.data[0].callid_nmbr})
                this.setState({ownContact: response.data[0]})
                this.setState({data: response.data[0]});
                this.setState({selectedGreeting: response.data[0].vmail_greeting});
            }
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false})
        });

    }


    saveHandler = () => {
        const payLoad = new FormData();
        payLoad.append('uid', this.state.uid);
        payLoad.append('user', this.state.username.split("@")[0]);
        payLoad.append('domain', this.state.signinDomain);

        payLoad.append('vmail_enabled', this.state.data.vmail_enabled);
        payLoad.append('vmail_sort_lifo', this.state.data.vmail_sort_lifo);
        payLoad.append('vmail_annc_time', this.state.data.vmail_annc_time);
        payLoad.append('vmail_annc_cid', this.state.data.vmail_annc_cid);
        payLoad.append('data_limit', this.state.data.data_limit);
        payLoad.append('vmail_notify', this.state.data.vmail_notify);
        payLoad.append('cloud_id', this.state.cloudId);

        if (this.state.data.vmail_notify !== "no"){
            payLoad.append('vmail_transcribe', this.state.data.vmail_transcribe);
        }

        this.saveNotes(this.state.data.notes);
        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=update&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{
            this.setState({loading: false});
            toast.success("Successfully Updated Settings");
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false})
        });
    }

   getNotes = (userName, password) =>{

    const payLoad = { 
        cloud_id:"YOVU",
        cloud_username:userName,
        cloud_password: password,
    }

    axios.post(`${process.env.REACT_APP_API_URL}getcustomWebTabNotes`, payLoad)
    .then(response=>{
        this.setState({loading: false});
        let data = this.state.data;
        data['notes'] = response.data.notes;
        this.setState({ data });
        toast.success("Successfully Updated Settings");
    })
    .catch(err=>{
        toast.error(err.message);
        this.setState({loading: false})
    });

    }
    saveNotes = (notes) => {
      
   
        const payLoad = { 
            cloud_id:"YOVU",
             cloud_username:this.state.username,
             cloud_password: this.state.password,
            notes: notes
        }
    
        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_URL}setcustomWebTabNotes`, payLoad)
        .then(response=>{
            this.setState({loading: false});
            toast.success("Successfully Updated Settings");
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false})
        });
    }
    cancelHandler = () => {
        window.location.reload();
    }

    getAnsweringRules = (uType, userName, domain, password, cloudId) => {

        const payLoad = new FormData();
        payLoad.append('user', userName.split("@")[0]);
        payLoad.append('domain', domain);
        payLoad.append('uid', this.state.uid);
        payLoad.append('cloud_id', cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=answerrule&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            let arr = [];
            response.data.forEach((item, index)=> {
                arr.push(item.priority);
            });

            this.setState({itemsArray: arr});

            arr = [];
            response.data.forEach((item, index)=> {
                arr.push(item.time_frame);
            });

            this.setState({itemsArrayDisplay: arr});

            const items = response.data.map((value, index) => {
                let displayValue = value.time_frame;
                if(displayValue === "*"){
                    displayValue = "Default";
                }
                return(<option value={value.priority}> {displayValue}</option>)
    
            });

            this.setState({answeringItems: items});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false})
        });
    }

    getGreetings = (uType, userName, domain, password, cloudId) => {

        const payLoad = new FormData();
        payLoad.append('user', userName.split("@")[0]);
        payLoad.append('domain', domain);
        payLoad.append('type', 'greeting');
        payLoad.append('cloud_id', cloudId);

        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=audio&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{

            var jsonArray = [];
            for(var key in response.data){
                if(response.data[key]["remotepath"]){
                    const searchRegExp = /amp;/g;
                    response.data[key]["remotepath"] = response.data[key]["remotepath"].replace(searchRegExp,"");
                }
                jsonArray.push(response.data[key]);
            }

            let greetingsFiles = {}
            let items = [];
            items.push(<option key={"greet"} value={""}> {""}</option>)
            jsonArray.map((value, index) => {
                greetingsFiles[value.index] = value.remotepath;
                items.push(<option key={"greet"+index} value={value.index}> {value.index + " - " + value.moh_script}</option>)
            });

            if(jsonArray.length > 0){
                this.setState({lastIndexOfGreeting: jsonArray[jsonArray.length -1].index});
            }

            this.setState({greetingItems: items});
            this.setState({greetingsFiles: greetingsFiles})
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false})
        });
    }

    answeringRuleChangeHandler = (evt) => {
        const index = this.state.itemsArray.indexOf(evt.target.value);
        if(index > -1){
            this.state.itemsArray.splice(index, 1);
            this.state.itemsArrayDisplay.splice(index, 1);
            
        }

        this.state.itemsArray.splice(0, 0, evt.target.value);
        this.state.itemsArrayDisplay.splice(0, 0, evt.target.options[evt.target.selectedIndex].innerHTML);
        

        const items = this.state.itemsArray.map((value, index) => {
            let displayValue =  this.state.itemsArrayDisplay[index];
            if(displayValue === "*"){
                displayValue = "Default";
            }
            return(<option value={value}> {displayValue}</option>)

        });

        this.setState({answeringItems: items});
        this.setState({anseringRuleValue: evt.target.value});

        let priority = "";

        this.state.itemsArray.forEach((item, index)=> {
            priority += item
            if(index < (this.state.itemsArray).length - 1){
                priority += ","
            }
        });

        const payLoad = new FormData();
        payLoad.append('user', this.state.username.split("@")[0]);
        payLoad.append('domain', this.state.signinDomain);
        payLoad.append('uid', this.state.uid);
        payLoad.append('priority', priority);
        payLoad.append('cloud_id', this.state.cloudId);

        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=answerrule&action=reorder&application=webphone&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{
            toast.success("Order Changed");
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false})
        });
        
    }
    makeid = (length) => {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
        return result.join('');
    }

    greetingHandler = (evt) => {
        if(this.state.userDeviceSelected === ""){
            toast.error("Please Select the device first");
            return;
        }
        const payLoad = new FormData();
        payLoad.append('callid', this.makeid(10) + this.state.username);
        payLoad.append('uid', this.state.uid);
        payLoad.append('destination', "sip:"+ this.state.userDeviceSelected + "@" + this.state.username.split('@')[1]);
        payLoad.append('type', "greeting");
        payLoad.append('index', (parseInt(this.state.lastIndexOfGreeting) + 1));
        payLoad.append('ani', this.state.username.split('@')[0]);
        payLoad.append('description', this.state.greetingDescription);
        payLoad.append('cloud_id', this.state.cloudId);

        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=call&action=call_to_record&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{
            toast.info("You will get a call to record greeting in a while");
            this.setState({greetingDescription: ""});
            this.setState({showGreetingInfo: false});
            this.setState({userDeviceSelected: ""});
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false})
        });
    }

    getDeviceInfoForGreeting = (userName,domain, uType, password,cloudId) => {
    
        const payLoad = new FormData();
        payLoad.append('user', userName.split("@")[0]);
        payLoad.append('domain', domain);
        payLoad.append('cloud_id', cloudId);
        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=device&action=read&utype=${uType}&cloud_username=${userName}&cloud_password=${password}`, payLoad)
        .then(response=>{
            const items = [];
            items.push(<option value={""}> {""}</option>)
            response.data.map((value, index) => {
                let displayValue = value.aor.split("@")[0];
                displayValue = displayValue.replace("sip:", "");
                items.push(<option key={index} value={displayValue}> {displayValue}</option>)
    
            });
    
            this.setState({userDevices: items});
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false});
        });
    }

    greetingChangeHandler = (evt) => {

        this.setState({selectedGreeting: evt.target.value});
    
        const payLoad = new FormData();
        payLoad.append('user', this.state.username.split("@")[0]);
        payLoad.append('domain', this.state.signinDomain);
        payLoad.append('uid', this.state.uid);
        payLoad.append('vmail_greeting', evt.target.value);
        payLoad.append('cloud_id', this.state.cloudId);
        
        this.setState({loading: true});
        axios.post(`${process.env.REACT_APP_API_NS_URL}?format=json&object=subscriber&action=update&utype=${this.state.uType}&cloud_username=${this.state.username}&cloud_password=${this.state.password}`, payLoad)
        .then(response=>{
            this.setState({loading: false});
        })
        .catch(err=>{
            toast.error(err.message);
            this.setState({loading: false});
        });
    }

    messageEnded = () => {
        if(this.state.audioSource !== ''){
            this.playRef.setAttribute("style", "display: bold;");
            this.stopRef.setAttribute("style", "display: none;");
            this.setState({audioSource: ''});
        }
    }

    playHandler = (event) =>{
        //event.removeClass('feather');
        if(this.state.selectedGreeting === "" || !this.state.selectedGreeting){
            return;
        }
        this.playRef.setAttribute("style", "display: none;");
        this.stopRef.setAttribute("style", "display: bold;");
        this.setState({audioSource: this.state.greetingsFiles[this.state.selectedGreeting]})
    }
    
    pauseHandler = (event) =>{
        this.playRef.setAttribute("style", "display: bold;");
        this.stopRef.setAttribute("style", "display: none;");
        this.setState({audioSource: ''});
    }


    render() {

        return(
            <Auxi>

                {
                    this.state.direct === "0" && this.state.showEngage !== null ?
                        <AppLayout cloud_id={this.state.cloudId}
                            display_name = {this.state.username}
                            ownContact = {this.state.ownContact}
                            fromPage = "voiceMailSettings"
                            showEngage={this.state.showEngage}
                            url={this.state.url}/>
                    : null
                }

                <div className = "CustomMainIndexApp vmSettingsPage">
                    <div className="row voice-message-layout">
                        <div className="col-xs-0 col-md-2"> </div>
                        <div className = "col-xs-12 col-md-8">
                            <div className="page-header customTitleMiddle">
                                <h3 className="m-b-10">
                                    {this.state.loading
                                    ? <Spinner data="" />
                                    : null
                                    }
                                </h3>
                            </div>

                            <h5> Answering Rules </h5>
                            <hr/>

                            <div className="row" style={{marginTop:"20px"}}>
                                
                                <div className="col-md-4">
                                    <label>Reorder </label>
                                </div>
                                <div className="col-md-8">
                                    <select className="form-control"
                                        onChange={this.answeringRuleChangeHandler}
                                        value={this.state.anseringRuleValue} name="anseringRuleValue">

                                        {this.state.answeringItems}

                                    </select>
                                    
                                </div>
                            </div>
                            <h5> Notes </h5>
                            <hr/>

                            <div className="row" style={{marginTop:"20px"}}>
                                
                                <div className="col-md-4">
                                    <label>Status Message </label>
                                </div>
                                <div className="col-md-8">
                                <input type="text" className="form-control"   value={this.state.data.notes}  name="notes" onChange={this.inputChangeHandler} />
                                    
                                </div>
                            </div>

                            <h5 style={{marginTop:"30px"}}> Voicemail Settings </h5>
                            <hr/>
                            <div className="row form-group vmEnable">
                                <div className="custom-control custom-checkbox">

                                    <label>  
                                        <input
                                            name="vmail_enabled"
                                            type="checkbox"
                                            checked={this.state.data.vmail_enabled === "yes"}
                                            onChange={this.inputChangeHandler} />
                                        Enable Voicemails
                                    </label>
                                </div>
                            </div>
                            <h5> Inbox </h5>
                            <hr/>

                            <div className="row">
                                
                                <div className="col-md-4">
                                    <label>Options</label>
                                </div>
                                <div className="col-md-8">
                                    <div className="custom-control custom-checkbox">

                                        <label>  
                                            <input
                                                name="vmail_sort_lifo"
                                                type="checkbox"
                                                checked={this.state.data.vmail_sort_lifo === "yes"}
                                                onChange={this.inputChangeHandler} />
                                            Sort voicemail inbox by latest first
                                        </label>
                                    </div>
                                    <div className="custom-control custom-checkbox">

                                        <label>  
                                            <input
                                                name="vmail_annc_time"
                                                type="checkbox"
                                                checked={this.state.data.vmail_annc_time === "yes"}
                                                onChange={this.inputChangeHandler} />
                                            Announce voicemail received time
                                        </label>
                                    </div>
                                    <div className="custom-control custom-checkbox">

                                        <label>  
                                            <input
                                                name="vmail_annc_cid"
                                                type="checkbox"
                                                checked={this.state.data.vmail_annc_cid === "yes"}
                                                onChange={this.inputChangeHandler} />
                                            Announce incoming call ID
                                        </label>
                                    </div>
                                </div>
                            </div>


                            


                            <h5 style={{marginTop:"30px"}}> Greetings </h5>
                            <hr/>


                            <div className="row" style={{marginTop:"20px"}}>
                                
                                <div className="col-8 col-md-4">
                                    <label> Record Voicemail Greeting</label>
                                </div>
                                <div className="col-4 col-md-8">
                                    <span className="vol-span" onClick={e => this.setState({showGreetingInfo: !this.state.showGreetingInfo})}> <FontAwesomeIcon icon={faVolumeUp} /> </span>
                                </div>

                                <AudioPlayer
                                    autoPlay
                                    preload={"none"}
                                    src={this.state.audioSource}
                                    onEnded={e => this.messageEnded()}
                                />
                            </div>
                            {
                                this.state.showGreetingInfo?    
                                    <div className="row">
                                        <div className="col-8 col-md-4">
                                            <label>Add Description</label>
                                        </div>
                                        <div className="col-8 col-md-4">
                                            <input className="form-control" type="text" onChange={e => this.setState({greetingDescription: e.target.value})} 
                                                value={this.state.greetingDescription}/>
                                        </div>
                                        <div className="col-8 col-md-4" style={{marginTop:"10px"}}>
                                            <label>Choose Device</label>
                                        </div>
                                        <div className="col-8 col-md-4">
                                            <select className="form-control"
                                                onChange={e => this.setState({userDeviceSelected: e.target.value})}
                                                value={this.state.userDeviceSelected} name="userDeviceSelected">

                                                {this.state.userDevices}

                                            </select>
                                            
                                        </div>
                                        <div className="col-8 col-md-8" style={{marginTop:"10px", textAlign: "center"}}>
                                            <button type="button" onClick={this.greetingHandler}
                                                className="btn btn-info">
                                                CALL ME
                                            </button>
                                            
                                        </div>
                                    </div>
                                : null
                            }
                            

                            <div className="row" style={{marginTop:"20px"}}>
                                
                                <div className="col-12 col-md-12">
                                    <label>Greetings</label>
                                </div>
                                <div className="col-8 col-md-8">
                                    <select className="form-control"
                                        onChange={this.greetingChangeHandler}
                                        value={this.state.selectedGreeting} name="selectedGreeting">

                                        {this.state.greetingItems}

                                    </select>
                                </div>

                                <div className="col-4 col-md-4">
                                    <a ref={(ref) => this.playRef = ref} onClick={this.playHandler}
                                        title="Play" className="greeting-play-pause">
                                        <FontAwesomeIcon 
                                            icon={faPlayCircle} className="custom-data-table-icon"/>
                                    </a>
                                    
                                    <a ref={(ref) => this.stopRef = ref} onClick={this.pauseHandler}
                                        title="Stop" style={{display:"none"}} className="greeting-play-pause">
                                        <FontAwesomeIcon 
                                            icon={faPauseCircle} className="custom-data-table-icon"/> 
                                    </a>
                                </div>
                            </div>



                            <h5 style={{marginTop:"30px"}}> Unified Messaging </h5>
                            <hr/>

                            <div className="row" style={{marginTop:"20px"}}>
                                
                                <div className="col-md-4">
                                    <label>Email Notification</label>
                                </div>
                                <div className="col-md-8">
                                    <select className="form-control"
                                        onChange={this.inputChangeHandler}
                                        value={this.state.data.vmail_notify} name="vmail_notify">

                                        <option value="no">None</option>
                                        <option value="yes">Send w/ hyperlink</option>
                                        <option value="brief">Send w/ brief hyperlink</option>
                                        <option value="attnew">Send w/ attachment (leave as new)</option>
                                        <option value="attsave">Send w/ attachment (move to saved)</option>
                                        <option value="atttrash">Send w/ attachment (move to trash)</option>
                                        <option value="briefattnew">Send w/ brief attachment (leave as new)</option>
                                        <option value="briefattsave">Send w/ brief attachment (move to saved)</option>
                                        <option value="briefatttrash">Send w/ brief attachment (move to trash)</option>

                                    </select>
                                    
                                </div>
                            </div>
                            {
                                this.state.data.vmail_notify !== "no" ?
                                    <div className="row" style={{marginTop:"20px"}}>
                                    
                                        <div className="col-md-4">
                                            <label>Voicemail Transcription</label>
                                        </div>
                                        <div className="col-md-8">
                                            <select className="form-control"
                                                onChange={this.inputChangeHandler}
                                                value={this.state.data.vmail_transcribe} name="vmail_transcribe">

                                                <option value="no" selected="selected">Disabled</option>
                                                <option value="google">Enabled</option>

                                            </select>
                                            
                                        </div>
                                    </div>
                                :
                                    null
                            }


                            <div href="" className="floating-footer">

                                <button  onClick ={this.cancelHandler} type="button"
                                    className="btn btn-dark">
                                    CANCEL
                                </button>
                                
                                <button type="button" onClick={this.saveHandler}
                                    className="btn btn-primary">
                                    SAVE
                                </button>

                            </div>
                            
                        </div>
                        <div className="col-xs-0 col-md-2"></div>
                    </div>
                </div>
            </Auxi>

        );

    }

}

export default VoiceMailSettings;
